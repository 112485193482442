<template>
  <iframe 
    v-if="getCurrentLesson.mediaType === 'panda' && getCurrentLesson.is_liberated && getCurrentLesson.source
    && (!videoEnded || !isAutoPlay)"
    allow="fullscreen;autoplay"
    id="panda-player"
    :src="`${getCurrentLesson.source}&autoplay=${options.autoplay}`"
    :style="`${calcHeight}`"
    style="position: relative; border: 10px; width: 100%; max-width: 980px; min-height: 100%;"
  ></iframe>
  <Autoplay v-else-if="isAutoPlay === true && videoEnded === true && getCurrentLesson.source"
    :nextLesson="getNextLesson"
    :thumbLesson="getCurrentLesson.media.media_meta[0].value"
    :thumbNextLesson="thumbNextLesson"
    :currentLesson="getCurrentLesson"
    :isMobile="isMobile"
    :isFullScreen="isFullScreen"
    @cancelAutoplay="videoEnded = false"
  />
  <p v-else style="color: white;"> Vídeo não encontrado</p>
</template>
<script>
// import notify from "@/services/libs/notificacao";
import { mapGetters, mapActions } from "vuex";
import Autoplay from "@/components/V2/player/autoplay/index";
export default {
  props: ["porcentagem", "isMobile"],
  data() {
    return {
      videoEnded: false,
      height: 500,
      options: {
        muted: false,
        autoplay: false,
      },
      playerReady: false,
      t: "",
      panda_ended: false,
      player: null,
      fisrtStart: false,
      isAutoPlay: true,
      isFullScreen: false,
      rewatchVideo: false,
      fullscreenEvents: ["fullscreenchange", "webkitfullscreenchange", "mozfullscreenchange", "MSFullscreenChange"],
    };
  },
  components: { 
    Autoplay
  },
  computed: {
    ...mapGetters(['getCurrentLesson', 'getNextLesson', 'getHasNextLesson', 'getIsAutoplay', 'getHasNextModule', 'getNextModule']),
    calcHeight() {
      if (this.isMobile) {
        return 'min-height: 250px !important;'
      } else {
        return 'min-height: 530px !important;'
      }
    },
    thumbNextLesson(){
      if (this.getNextLesson.custom_tumb != null){
        return this.getNextLesson.custom_tumb
      } else {
        if(this.getNextLesson.mediaType == 'panda'){
          return this.getNextLesson.media.media_meta[0].value
        }
        if(this.getNextLesson.mediaType == 'youtube' || this.getNextLesson.mediaType == 'vimeo'){
          return this.getNextLesson.thumb
        }
      }
    },
  },
  methods: {
    ...mapActions([
      "setIsAutoplay",
    ]),
    async calcPercent() {
      try {
        this.isAutoPlay = localStorage.getItem('autoplay') === 'true';
        if(this.videoEnded === false){  
          window.pandascripttag = window.pandascripttag || [];
          await window.pandascripttag.push(() => {
            const player = new PandaPlayer("panda-player");
            setTimeout(() => {
              var current = player.getCurrentTime();
              var total = player.getDuration();

              current = parseInt(current, 10);
              total = parseInt(total, 10);
    
              var calc = (current / total) * 100;
              calc = parseInt(calc, 10);

              if((calc === 100 || (calc >= 99 && totalTime - time <= 1)) && (this.getHasNextLesson || (this.getHasNextModule && this.getNextModule.is_liberated))){
                this.videoEnded = true
                this.rewatchVideo = true
              }

              if (calc !== NaN && this.rewatchVideo === false) {
                this.$store.commit('setCurrentLesson', {
                  ...this.$store.getters.getCurrentLesson,
                  percent: calc
                })
              }
            }, 1000);
          });
        }
      } catch (e) {
        window.pandascripttag = [];
        document.getElementById("fkdjshfkjsdhfkjdsh").remove();
      }
    },
    verifyIfCountPercent() {
      this.calcPercent();
      if (this.getCurrentLesson.mediaType === 'panda') {
        setTimeout(() => {
          this.verifyIfCountPercent();
        }, 1000);
      } else {
        window.pandascripttag = [];
        document.getElementById("fkdjshfkjsdhfkjdsh").remove();
      }
    },
    checkFullscreen() {
      const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
      this.isFullScreen = !!fullscreenElement;
    },
    addCheckFullScreen() {
      this.fullscreenEvents.forEach(event => {
        document.addEventListener(event, this.checkFullscreen);
      });
    },
    removeCheckFullscreen() {
      this.fullscreenEvents.forEach(event => {
        document.removeEventListener(event, this.checkFullscreen);
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.options.autoplay = this.getIsAutoplay
      this.setIsAutoplay(false)
      this.verifyIfCountPercent();
    })
    this.addCheckFullScreen()
    try {
      if (this.getCurrentLesson.mediaType === 'panda') {
        window.pandascripttag = window.pandascripttag || [];
        window.pandascripttag.push(() => {
          this.player = new PandaPlayer("panda-player");
        });
        if (!document.getElementById("fkdjshfkjsdhfkjdsh")) {
          let pandaScript = document.createElement("script");
          pandaScript.setAttribute(
            "src",
            "https://player.pandavideo.com.br/api.js"
          );
          pandaScript.setAttribute("id", "fkdjshfkjsdhfkjdsh");
          document.head.appendChild(pandaScript);
        }
      }
    } catch (e) {
      window.pandascripttag = [];
      document.getElementById("fkdjshfkjsdhfkjdsh").remove();
    }
  },
  beforeDestroy() {
    this.removeCheckFullscreen()
    document.getElementById("fkdjshfkjsdhfkjdsh").remove();
  },
};
</script>

<style>
.toZindex .playerVimeo {
  z-index: 99999999999999999;
}
iframe {
  max-width: 980px;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
